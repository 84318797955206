import swal from "sweetalert";
import Swal from "sweetalert2";

let today = new Date();
let mm = today.getMonth() + 1;
let dd = today.getDay();
let yyyy = today.getFullYear();

window.currentDate = (mm < 10 ? '0' : '') + mm + "/" + (dd < 10 ? '0' : '') + dd + "/" + yyyy;

const logout = () => {
    return new Promise(resolve => {
        var settings = {
            "url": "/Mandaluyong/OnlineMarket/logout",
            "method": "POST",
            "headers": {
                "Content-Type": "application/json"
            },
            "data": JSON.stringify({
                _token: $('input[name=_token]').val(),
                key: $('input[name=_session_key]').val()
            })
        };
        $.ajax(settings)
            .always(function (msg) {
                resolve(msg);
            });
    });
}

const changePass = () => {
    return new Promise(resolve => {
        var settings = {
            "url": "/Mandaluyong/OnlineMarket/api/password",
            "method": "POST",
            "headers": {
                "Content-Type": "application/json"
            },
            "data": JSON.stringify({
                _token: $('input[name=_token]').val(),
                key: $('input[name=_session_key]').val(),
                old_pass: $('#changePassModal').find('#old_pass').val(),
                new_pass: $('#changePassModal').find('#new_pass').val(),
                conf_pass: $('#changePassModal').find('#conf_pass').val(),
            })
        };
        $.ajax(settings)
            .always(function (msg) {
                resolve(msg);
            });
    });
}

const swalMessage = (icon, mes) => {
    Swal.fire({
        icon: icon,
        html: mes,
        focusConfirm: false,
        confirmButtonText:  'Okay'
    })
}

$(() => {

    $(".datepicker").datepicker({
        uiLibrary: 'bootstrap',
        dateFormat: 'mm-dd-yy'
    });

    $('#nav-icon1,#nav-icon2,#nav-icon3,#nav-icon4').click(function () {
        $(this).toggleClass('open');
        $('.side-nav-2').toggleClass('open');
    });

    $('#fromDate').datepicker({
        uiLibrary: 'bootstrap',
        dateFormat: 'mm-dd-yy'
    });
    $('.datepicker').datepicker({
        uiLibrary: 'bootstrap',
        dateFormat: 'mm-dd-yy'
    });
    /* logout */
    $('#logout').on('click', function () {
        Swal.fire({
            icon: 'question',
            html: "Are you sure you want to logout from the system?",
            showDenyButton: true,
            confirmButtonText: 'YES',
            denyButtonText: `NO `,
        }).then((result) => {
            if (result.isConfirmed) {
                logout().then((val) => {
                    if (val.response.success) {
                        location.reload();
                    }
                })
            } else if (result.isDenied) {
            }
        })

        /* swal({
                title: "Confirm",
                text: "Logout?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then((resp) => {
                if (resp) {
                    logout().then((val) => {
                        if (val.response.success) {
                            location.reload();
                        }
                    })
                }
            }); */
    });

    $('#change_pass').on('click', function() {
        $('#changePassModal').modal('show');
    })

    $('#changePassModal').find('#toggle_old').on('click', function() {
        let type = $('#old_pass').attr('type') == "password" ? "text" : "password";
        $('#old_pass').attr('type', type);
        $(this).toggleClass('fa-eye');
        $(this).toggleClass('fa-eye-slash');
    })

    $('#changePassModal').find('#toggle_new').on('click', function() {
        let type = $('#new_pass').attr('type') == "password" ? "text" : "password";
        $('#new_pass').attr('type', type);
        $(this).toggleClass('fa-eye');
        $(this).toggleClass('fa-eye-slash');
    })

    $('#changePassModal').find('#toggle_conf').on('click', function() {
        let type = $('#conf_pass').attr('type') == "password" ? "text" : "password";
        $('#conf_pass').attr('type', type);
        $(this).toggleClass('fa-eye');
        $(this).toggleClass('fa-eye-slash');
    })

    $('#changePassModal').find('#proceed').on('click', function() {
        changePass().then((response) => {
            if (response.response.success) {
                swal({
                    title: "Success",
                    text: response.response.outMessage,
                    icon: "success"
                })
                 $('#changePassModal').modal('hide');
            } else {
                let msgs = "";
                if(response.response.errors !== undefined) {
                    response.response.errors.forEach((element) => {
                        msgs += element + "\n";
                    })
                }else {
                    msgs = response.response.outMessage;
                }
                swal({
                    title: "Error",
                    text: msgs,
                    icon: "error"
                })
            }
        });
    })

    $('#changePassModal').on('hidden.bs.modal', function() {
        $('#changePassModal').find('#old_pass').val('');
        $('#changePassModal').find('#new_pass').val('');
        $('#changePassModal').find('#conf_pass').val('');
    })
});

(function (cursorManager) {

    //From: http://www.w3.org/TR/html-markup/syntax.html#syntax-elements
    var voidNodeTags = ['AREA', 'BASE', 'BR', 'COL', 'EMBED', 'HR', 'IMG', 'INPUT', 'KEYGEN', 'LINK', 'MENUITEM', 'META', 'PARAM', 'SOURCE', 'TRACK', 'WBR', 'BASEFONT', 'BGSOUND', 'FRAME', 'ISINDEX'];

    //From: https://stackoverflow.com/questions/237104/array-containsobj-in-javascript
    Array.prototype.contains = function (obj) {
        var i = this.length;
        while (i--) {
            if (this[i] === obj) {
                return true;
            }
        }
        return false;
    }

    //Basic idea from: https://stackoverflow.com/questions/19790442/test-if-an-element-can-contain-text
    function canContainText(node) {
        if (node.nodeType == 1) { //is an element node
            return !voidNodeTags.contains(node.nodeName);
        } else { //is not an element node
            return false;
        }
    };

    function getLastChildElement(el) {
        var lc = el.lastChild;
        while (lc && lc.nodeType != 1) {
            if (lc.previousSibling)
                lc = lc.previousSibling;
            else
                break;
        }
        return lc;
    }

    //Based on Nico Burns's answer
    cursorManager.setEndOfContenteditable = function (contentEditableElement) {

        while (getLastChildElement(contentEditableElement) &&
            canContainText(getLastChildElement(contentEditableElement))) {
            contentEditableElement = getLastChildElement(contentEditableElement);
        }

        var range, selection;
        if (document.createRange) //Firefox, Chrome, Opera, Safari, IE 9+
        {
            range = document.createRange(); //Create a range (a range is a like the selection but invisible)
            range.selectNodeContents(contentEditableElement); //Select the entire contents of the element with the range
            range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
            selection = window.getSelection(); //get the selection object (allows you to change selection)
            selection.removeAllRanges(); //remove any selections already made
            selection.addRange(range); //make the range you have just created the visible selection
        } else if (document.selection) //IE 8 and lower
        {
            range = document.body.createTextRange(); //Create a range (a range is a like the selection but invisible)
            range.moveToElementText(contentEditableElement); //Select the entire contents of the element with the range
            range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
            range.select(); //Select the range (make it the visible selection
        }
    }

}(window.cursorManager = window.cursorManager || {}));

/* For Months */
/* USAGE window.months[(month index)] */
window.months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
/* USAGE window.short_months[(month index)] */
window.short_months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

/* For Decimals  */
/* USAGE on keyup of textField
    $('selector').on('keyup', function (e) {
        $(this).val(window.addCommas($(this).val()));
    });
 */
window.addCommas = (s) => {
    let string = s.replace(/,/g, '').split('.');
    const number = string[0].split('').reverse();
    let newAmount = "";
    for (let i = 0; i < number.length; i++) {
        newAmount = number[i] + newAmount;
        if ((i + 1) % 3 == 0) {
            if (number[i + 1] !== undefined) {
                newAmount = "," + newAmount;
            }
        }
    }
    if (string[1] !== undefined) {
        newAmount += "." + string[1];
    }
    return newAmount;
}

window.dateTyping = (s) => {
    let x = s.replace(/\//g, '');
    let newX = '';
    for (let i = 0; i < x.length; i++) {
        newX += x[i];
        if(i == 1 || i == 3) {
            newX += '/';
        }
    }
    return newX;
}


/*
let tmp = weightsMeasures.rows().data().toArray();
let test = tmp.findIndex((elemt) => {
    return elemt.class == data.class && elemt.type == data.type;
});
if(test !== undefined) {
    tmp.splice(test, 1);
}
weightsMeasures.clear().draw();
weightsMeasures.rows.add(tmp).draw();
*/
